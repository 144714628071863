html, body, #root, .App {
  height: 100%;
}

.App {
  text-align: center;
}

.question, .answer {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

#quiz {
  font-size: 1.2rem;
}

input {
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  #quiz {
    font-size: 2rem;
  }

  input {
    font-size: 2rem;
  }
}